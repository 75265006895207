<template>
  <a-modal
    :visible="value"
    title="新建经销商分组"
    :confirmLoading="okLoading"
    :mask-closable="false"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 90px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 90px)'}"
    >
      <a-form-model-item label="分组类型" prop="groupType">
        <a-select
          v-model="form.groupType"
          style="width: 300px;"
        >
          <a-select-option
            v-for="item in typeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin: 0 0 0 8px;">
          {{ form.groupType === 1 ? '所有人可见' : '仅自己可见' }}
        </span>
      </a-form-model-item>
      <a-form-model-item label="分组名称" prop="groupName">
        <a-input
          v-model.trim="form.groupName"
          allow-clear
          placeholder="请输入分组名称"
          style="width: 300px;"
        />
      </a-form-model-item>
      <template v-if="form.groupType === 1">
        <a-form-model-item label="负责人" prop="leaders">
          <a-button type="primary" @click="handleStaffSelect('leaders')">添加/编辑员工</a-button>
          <div class="tag-wrap">
            <a-tag v-for="item in form.leaders" :key="item.id" color="blue">
              {{ item.name }}
            </a-tag>
          </div>
        </a-form-model-item>
        <a-form-model-item label="协作人" prop="coordinators">
          <a-button type="primary" @click="handleStaffSelect('coordinators')">添加/编辑员工</a-button>
          <div class="tag-wrap">
            <a-tag v-for="item in form.coordinators" :key="item.id" color="blue">
              {{ item.name }}
            </a-tag>
          </div>
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'AddDealerGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          type: 1,
          name: '',
          leaders: [
            // { id: '1', name: '张三' }
          ],
          coordinators: [
            // { id: '2', name: '李四' }
          ]
        }
      }
    },
    okLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const checkLeaders = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('负责人不能为空'))
      } else if (value.length > 1) {
        callback(new Error('负责人只能选1人'))
      } else {
        callback()
      }
    }
    const checkCoordinators = (rule, value, callback) => {
      if (value.length > 20) {
        callback(new Error('协作人限20人'))
      } else {
        callback()
      }
    }

    return {
      rules: {
        groupType: [
          { required: true, message: '分组类型不能为空', trigger: 'change' }
        ],
        groupName: [
          { required: true, message: '分组名称不能为空', trigger: 'blur' }
        ],
        leaders: [
          { required: true, validator: checkLeaders, trigger: 'blur' }
        ],
        coordinators: [
          { validator: checkCoordinators, trigger: 'blur' }
        ]
      },
      typeList: [
        { label: '公共分组', value: 1 },
        { label: '私有分组', value: 2 }
      ]
    }
  },
  methods: {
    handleStaffSelect(type) {
      this.$emit('staff-select', type)
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('ok', { ...this.form })
        } else {
          return false
        }
      }) 
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}

.tag-wrap {
  margin: 8px 0 0;
  line-height: 22px;

  .ant-tag {
    margin-bottom: 8px;
  }
}
</style>
